document.addEventListener("DOMContentLoaded", function () {
	var widthTablet = window.matchMedia("(min-width: 1024px)");
	
	if (('.btn-wrap--reveal').length) {
		if (widthTablet.matches) {
			$('.btn-wrap--reveal').addClass('js-main-reveal');
		} else {
			$('.btn-wrap--reveal').find('.btn').addClass('js-main-reveal');
		}
	}
	
	function animationReveal() {
		var $mainAnimationDistance = '16px';
		var $projectsAnimationInterval = 300;
	
		if (widthLG.matches) {
			$mainAnimationDistance = '32px';
			$projectsAnimationInterval = 0;
		}
	
		if ($windowIsLoaded === true) {
	
			function afterRevealAnimation (el) {
				var $this = $(el);
				var $delay = 0;
	
				if ($this.hasClass('social-list--footer')) {
					var footerSocialItem = $('.social-list--footer .social-list__item');
					footerSocialItem.each(function () {
						$this = $(this);
						$this.css('transition-delay', $delay + 's');
						$delay = $delay + 0.25;
					});
					footerSocialItem.addClass('is-revealed');
				}
			}
	
			ScrollReveal().reveal('.js-main-reveal', {
				useDelay: 'once',
				duration: 850,
				interval: 300,
				easing: 'cubic-bezier(0.46, 0.03, 0.52, 0.96)',
				origin: 'bottom',
				distance: '16px',
				beforeReveal: afterRevealAnimation,
			});
	
			ScrollReveal().reveal('.js-project-list-reveal', {
				delay: 600,
				useDelay: 'onload',
				duration: 850,
				interval: 300,
				easing: 'cubic-bezier(0.46, 0.03, 0.52, 0.96)',
				origin: 'bottom',
				distance: $mainAnimationDistance,
			});
	
			ScrollReveal().reveal('.js-project-reveal', {
				delay: 600,
				useDelay: 'onload',
				duration: 850,
				interval: $projectsAnimationInterval,
				easing: 'cubic-bezier(0.46, 0.03, 0.52, 0.96)',
				origin: 'bottom',
				distance: $mainAnimationDistance,
			});
	
			ScrollReveal().reveal('.js-team-reveal', {
				duration: 850,
				interval: 150,
				easing: 'cubic-bezier(0.46, 0.03, 0.52, 0.96)',
				origin: 'bottom',
				distance: '8px',
			});
	
			ScrollReveal().reveal('.js-contacts-reveal', {
				delay: 1800,
				useDelay: 'onload',
				interval: 250,
				easing: 'cubic-bezier(0.46, 0.03, 0.52, 0.96)',
			});
		}
	}
	var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	var $orientation = window.matchMedia("(orientation: landscape)");
	var widthSM = window.matchMedia("(min-width: 768px)");
	var widthMD = window.matchMedia("(min-width: 1024px)");
	var widthLG = window.matchMedia("(min-width: 1280px)");
	
	var pageHeader = $('.page-header');
	var menuToggle = $('.js-menu-toggle');
	var targetMenu = document.querySelector('.page-header');
	var targetPreloader = document.querySelector('.preloader');
	var targetVideo = document.querySelector('.page-video');
	var targetPage = document.querySelector('body');
	
	if (iOS) {
		document.addEventListener('gesturestart', function (e) {
			e.preventDefault();
		});
	}
	
	function closeMenu() {
		menuToggle.removeClass('is-active');
	
		pageHeader.removeClass('page-header--opened');
		pageHeader.removeClass('page-header--content');
	
		$('.page-header__nav-item').removeClass('animated');
		$('.page-header__content *').removeClass('animated');
		$('.page-header__nav').fadeOut(600);
		$('.page-header__content').fadeOut(600);
	
		if ($('.js-menu-content-open').length) {
			$('.js-menu-content-open').fadeIn(300);
		}
	
		bodyScrollLock.enableBodyScroll(targetMenu);
	}
	
	function openMenu() {
	
		bodyScrollLock.disableBodyScroll(targetMenu);
	
		if (!menuToggle.hasClass('is-active')) {
			menuToggle.addClass('is-active');
		}
	
		pageHeader.addClass('page-header--opened');
	
		$('.page-header__nav').slideDown(0, function () {
			$('.page-header__nav-item').addClass('animated');
		})
	}
	
	function openMenuContent() {
	
		bodyScrollLock.disableBodyScroll(targetMenu);
	
		if (!menuToggle.hasClass('is-active')) {
			menuToggle.addClass('is-active');
		}
	
		pageHeader.addClass('page-header--content');
	
		$('.page-header__content').slideDown(0, function () {
			$('.page-header__content *').addClass('animated');
		})
	}
	
	menuToggle.on('click', function (e) {
		e.preventDefault();
		var windowWidth = $(window).outerWidth();
		var $this = $(this);
	
		$this.toggleClass('is-active');
	
		if ($this.hasClass('is-active')) {
			openMenu();
		} else {
			closeMenu();
		}
	});
	
	$(document).on('click touchstart', function(event) {
	
		if ($(event.target).closest('.page-header__nav-list, .page-header__control, .js-menu-trigger').length === 0 && pageHeader.hasClass('page-header--opened')) {
	
			setTimeout(function () {
				closeMenu();
			},300);
		}
	
		if (($(event.target).closest('.page-header__content-inner, .js-menu-content-open').length === 0 && pageHeader.hasClass('page-header--content'))) {
	
			setTimeout(function () {
				closeMenu();
			},300);
		}
	
	});
	
	$('.js-menu-trigger').on('click', function(e) {
	
		if (e.target !== e.currentTarget) {
			return;
		} else {
			openMenu();
		}
	
	});
	
	$('.js-menu-content-open').on('click', function(e) {
		e.preventDefault();
		$(this).fadeOut(300);
		openMenuContent();
	});
	
	// FANCYBOX
	
	var fixedHeaderTemplate =
		'<div class="modal__header modal__header--fixed">' +
		'<div class="modal__header-container">' +
		'<div class="modal__header-row">' +
		'<div class="modal__header-col">' +
		'<div class="modal__header-wrapper">' +
		'<a class="modal__logo-link" href="/">' +
		'<img class="modal__logo-img" src="/logo.svg" alt="NORT.DESIGN">' +
		'</a>' +
		'<button class="modal__close-btn btn-close js-global-modal-close">' +
		'<span class="visuallyhidden">Закрыть</span>' +
		'</button>' +
		'</div>' +
		'</div>' +
		'</div>' +
		'</div>' +
		'</div>';
	
	$('[data-fancybox="images"]').fancybox({
		loop: true,
		gutter: 0,
		keyboard: true,
		arrows: false,
		infobar: false,
		smallBtn: false, // ??????
		toolbar: false,
		/*		idleTime: false,*/
		transitionDuration: 600,
		slideClass: 'projects-gallery-slide',
		baseClass: 'projects-gallery-layout',
		touch: {
			vertical: false
		},
		clickContent : "close",
		clickSlide : "close",
		dblclickContent: false,
		dblclickSlide: false,
		dblclickOutside: false,
	
		mobile : {
			clickContent : "close",
			clickSlide : "close",
			dblclickContent: false,
			dblclickSlide: false,
			dblclickOutside: false,
		},
		onInit: function(instance) {
			instance.$refs.inner.append(fixedHeaderTemplate);
		}
	});
	
	var targetModal = document.querySelector('.modal');
	
	$('.fancy-form').each(function() {
		var $this = $(this);
		var $baseTemplateClass = 'modal-form';
	
		if ($this.hasClass('fancy-form--black')) {
			$baseTemplateClass = 'modal-form modal-form--black'
		}
	
		$this.fancybox({
			baseTpl:
				'<div class="fancybox-container ' + $baseTemplateClass + '" role="dialog" tabindex="-1">' +
				'<div class="fancybox-bg"></div>' +
				'<div class="fancybox-inner">' +
				'<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
				'<div class="fancybox-toolbar">{{buttons}}</div>' +
				'<div class="fancybox-navigation">{{arrows}}</div>' +
				'<div class="fancybox-stage"></div>' +
				'<div class="fancybox-caption"><div class=""fancybox-caption__body"></div></div>' +
				'</div>' +
				'</div>',
			slideClass: 'modal-wrapper',
			defaultType: 'inline',
			autoFocus: true,
			backFocus: true,
			animationDuration: 600,
			touch: false,
			clickSlide: false,
			trapFocus: true,
			toolbar   : false,
			smallBtn: false,
			gutter: 0,
			video: {
				autoStart: true
			},
			afterLoad: function (instance, current) {
				var $this = $.fancybox.getInstance().$refs.inner;
				if ($this.find('video').length) {
					$this.find('video').trigger('play');
				}
			},
			beforeShow: function () {
				bodyScrollLock.disableBodyScroll(targetModal);
			},
			beforeClose: function (instance, current) {
				var $this = $.fancybox.getInstance().$refs.inner;
				if ($this.find('video').length) {
					$this.find('video').currentTime = 0;
					$this.find('video').trigger('pause');
				}
			},
			afterClose: function () {
				bodyScrollLock.enableBodyScroll(targetModal);
			}
		});
	});
	
	$('.js-modal-close').on('click', function(e) {
		e.preventDefault();
		$.fancybox.close();
	});
	
	$('.modal').on('click touchstart', function(event) {
		var $this = $(this);
	
		if ($this.hasClass('modal--closing')) {
			if ($(event.target).closest('.js-modal-element').length === 0) {
	
				setTimeout(function () {
					$.fancybox.close();
				},300);
			}
		}
	});
	
	
	$(document).on("click", ".js-global-modal-close" , function() {
		$.fancybox.close();
	});
	
	
	// WINDOW LOAD FUNCTION
	
	var $windowIsLoaded = false;
	
	$(window).on('load', function () {
	
		setTimeout(function () {
			$windowIsLoaded = true;
			$('.preloader').fadeOut(500);
			animationReveal();
		}, 750);
	});
	
	// RESIZE
	
	var resizeTimer;
	
	$(window).on('resize', function (e) {
	
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {
			videoSliderInit();
			if ($orientation.matches) {
				// fix for main page
				if ($('body').hasClass('js-main-page')) {
					window.scrollTo(0, 1);
					bodyScrollLock.disableBodyScroll(targetVideo);
				}
			} else {
	
			}
		}, 250);
	});
	
	// Map
	
	$('.js-map-sticker').on('click', function () {
		var $this = $(this);
		var $stickerID = $this.attr('data-sticker');
		var $stickerContent = $('#sticker-content').find('[data-sticker-content='+ $stickerID +']');
	
		$this.addClass('d-contacts__sticker--active').siblings().removeClass('d-contacts__sticker--active');
	
		$stickerContent.show(0, function() {
			$stickerContent.addClass('address-list__item--active').siblings().removeAttr('style').removeClass('address-list__item--active');
			setTimeout(function () {
				$stickerContent.find('.tap-list').addClass('tap-list--active');
				$stickerContent.siblings().find('.tap-list').removeClass('tap-list--active').removeClass('tap-list--hidden');
			}, 300);
		});
	});
	
	// Tabs
	
	$('.js-tabs-select-trigger').on('change', function () {
		var $this = $(this);
		var $tabID = $this.val();
		var $text = $this.find('option:selected').text();
		var $tabContent = $('[data-tab-content=' + $tabID + ']');
		$tabContent.addClass('tabs-content--active').siblings().removeClass('tabs-content--active');
		$this.parent().find('.js-tabs-select-label').html($text);
	
		if ($this.hasClass('tabs-select--tap-list')) {
			setTimeout(function () {
				$this.closest('.js-tabs').find('.tap-list__item').removeAttr('style');
				$this.closest('.js-tabs').find('.tap-list').removeClass('tap-list--hidden');
				$this.closest('.js-tabs').find('.tabs-content--active').find('.tap-list').addClass('tap-list--active');
				$this.closest('.js-tabs').find('.tabs-content--active').siblings().find('.tap-list').removeClass('tap-list--active');
			}, 300);
		}
	});
	// Scroll
	function getScrollbarWidth() {
		return window.innerWidth - document.documentElement.clientWidth;
	}
	
	function scrollBarCorrection() {
		$('.page-header').css('padding-right', $scrollBarWidth + 'px');
		$('body').css('padding-right', $scrollBarWidth + 'px');
	}
	
	function clearScrollBarCorrection() {
		$('.page-header').removeAttr('style');
		$('body').removeAttr('style');
	}
	
	var $scrollBarWidth = getScrollbarWidth();
	var $scrollPosition = $(window).scrollTop();
	var $scrolling = false;
	var $projectCard = $('.js-project-card-header');
	var $scrollingAnimationTime = 1200;
	
	if ($('.page__main').hasClass('page__main--project') && widthMD.matches && $scrollPosition === 0) {
		bodyScrollLock.disableBodyScroll(targetPage);
		scrollBarCorrection();
	}
	
	if ($('.page__main').hasClass('page__main--project') && widthMD.matches && $scrollPosition !== 0) {
		$projectCard.addClass('project-card__header--invisible');
	}
	
	function overlayFadeOut() {
		$scrolling = true;
		$projectCard.addClass('project-card__header--invisible');
	
		setTimeout(function () {
			bodyScrollLock.enableBodyScroll(targetPage);
			clearScrollBarCorrection();
	
			$scrolling = false;
		}, $scrollingAnimationTime);
	}
	
	function overlayFadeIn() {
		$scrolling = true;
		bodyScrollLock.disableBodyScroll(targetPage);
		scrollBarCorrection();
		$projectCard.removeClass('project-card__header--invisible');
	
		setTimeout(function () {
			$scrolling = false;
		}, $scrollingAnimationTime);
	}
	
	$('.js-project-overlay-hide').on('click', function () {
		if ($('.page__main').hasClass('page__main--project') && widthMD.matches) {
			overlayFadeIn();
		}
	});
	
	$(document).on("mousewheel DOMMouseScroll", function(e) {
			if ($scrolling) return;
	
			if ($('.page__main').hasClass('page__main--project') && widthMD.matches) {
				if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
				} else {
					overlayFadeOut();
				}
			}
	});
	
	$(window).scroll(function () {
		var currentScrollPosition = $(window).scrollTop();
	
		if ($('.page__main').hasClass('page__main--project') && widthMD.matches) {
	
			if (currentScrollPosition === 0) {
				setTimeout(function () {
					overlayFadeIn();
				}, 100);
			}
		}
	});
	
	$projectCard.on('click', function () {
		overlayFadeOut();
	});
	// POLYFILLS
	
	// Object fit IE
	$(function () { objectFitImages() });
	// INTRO PAGE
	
	var introContentOffset;
	var introLogoHeight = Math.ceil($('.page-logo__img-wrap').height());
	
	$('.js-main-page').bind('mousewheel', function (e) {
	
		if (widthMD.matches) {
	
			if (e.originalEvent.wheelDelta / 120 > 0) {
				if ($('.page-logo').hasClass('page-logo--active')) {
					$('.js-intro-trigger').click();
				}
			} else {
				if (!$('.page-logo').hasClass('page-logo--active')) {
					$('.js-intro-trigger').click();
				}
			}
		}
	});
	
	$('.js-intro-trigger').on('click', function () {
		var $this = $(this);
		introContentOffset = $('.page-logo__content').position();
	
		$this.toggleClass('page-logo--active');
	
		if ($this.hasClass('page-logo--active')) {
			$('.page-logo__img-wrap').css('top', introContentOffset.top - introLogoHeight / 2 + 'px');
		} else {
			$('.page-logo__img-wrap').removeAttr('style');
		}
	});
	
	$('.page-logo__btn').on('click', function (e) {
		e.stopPropagation();
	});
	// SLIDERS
	$('.js-lang-toggle').prop("disabled", true);
	
	function descSliderInit() {
	
		var descSliderInstances = [];
	
		if ($('.js-desc-slider').length) {
	
			$(".js-desc-slider").each(function (index, element) {
				var $this = $(this);
	
				var descSliderParams = {
					direction: 'horizontal',
					spaceBetween: 48,
					slidesPerView: 1,
					parallax: true,
					speed: 400,
					observer: true,
					observeParents: true,
					observeSlideChildren: true,
	/*				autoHeight: true,*/
					allowTouchMove: false,
					slideActiveClass: 'desc-slider__item--active',
					on: {
						init: function () {
	/*						var $this = this;
							$this.updateAutoHeight();*/
							$('.js-lang-toggle').prop("disabled", false);
						},
					},
				};
				descSliderInstances[index] = new Swiper($this, descSliderParams);
			});
		}
	}
	
	function setEnLang() {
	
		if ($(".js-desc-slider").length) {
	
			$(".js-desc-slider").each(function(index, element) {
				var $swiper = this.swiper;
				if ($swiper !== undefined) {
					$swiper.slideTo(0);
				}
			});
		}
	}
	
	function setRuLang() {
	
		if ($(".js-desc-slider").length) {
	
			$(".js-desc-slider").each(function(index, element) {
				var $swiper = this.swiper;
				if ($swiper !== undefined) {
					$swiper.slideTo(1);
				}
			});
		}
	}
	
	descSliderInit();
	
	$('.js-lang-toggle').on('click', function (e) {
		e.stopPropagation();
	
		$('.js-lang-toggle').each(function(){
			var $this = $(this);
			$this.toggleClass('is-en');
	
			$('.desc-slider__item').removeClass('desc-slider__item--active');
	
			setTimeout(function () {
				if ($this.hasClass('is-en')) {
					setEnLang();
					$this.find('.lang-btn__title').text('ru');
				} else {
					setRuLang();
					$this.find('.lang-btn__title').text('en');
				}
			}, 400)
		});
	})
	// VIDEO SLIDER
	
	var videoSliderInstances = [];
	
	function videoSliderInit() {
	
		if ($('.js-video-slider').length) {
	
			$(".js-video-slider").each(function (index, element) {
				var $this = $(this);
				var $swiper = this.swiper;
	
				if (!widthSM.matches) {
					if ($swiper !== undefined && $swiper !== null) {
						$swiper.destroy(true, true);
					}
					$swiper = undefined;
				}
	
				if (widthSM.matches) {
					if ($swiper === undefined || $swiper === null) {
						videoSliderInstances[index] = new Swiper($this, {
							direction: 'horizontal',
							spaceBetween: 0,
							slidesPerView: 1,
							loop: true,
							watchOverflow: true,
							breakpoints: {
								1280: {
									allowTouchMove: false,
								}
							},
							navigation: {
								prevEl: $this.parent().find(".js-video-slider-btn-prev"),
								nextEl: $this.parent().find(".js-video-slider-btn-next")
							},
							pagination: {
								el: $this.parent().find(".swiper-pagination")[0],
								type: 'bullets',
								clickable: true,
								hideOnClick: false
							},
							on: {
								slideChange: function (element) {
									var $activeSlide = $this.find('.swiper-slide-active');
									var $video = $activeSlide.find('.video-slider__video').get(0);
	
									if (!$video.paused) {
										$video.pause();
									}
								},
							}
						});
					}
				}
			});
	
		}
	}
	
	videoSliderInit();
	
	$('.js-start-video').on('click', function () {
		var $this = $(this);
		var $videoWrapper = $this.closest('.video-slider__item');
		var $coverWrapper = $this.closest('.video-slider__item').find('.video-slider__img-wrap');
	
		$videoWrapper.find('video').get(0).play();
		$coverWrapper.fadeOut(300);
	});
	
	
	$('.video-slider__video').each(function () {
		var $this = $(this);
		var $player = $('.player__audio').get(0);
	
		$this.on('play', function () {
	
			if ($('.js-play-toggle').length && !$player.paused) {
				$('.player__audio').addClass('was-played');
				$('.js-play-toggle').click();
			}
		});
	
		$this.on('ended', function () {
	
			if ($('.js-play-toggle').length && $('.player__audio').hasClass('was-played') && $player.paused) {
				$('.js-play-toggle').click();
				$('.player__audio').removeClass('was-played');
			}
		});
	});
	var bannerSliderInstances = [];
	
	function bannerSliderInit() {
	
		if ($('.js-banner-slider').length) {
	
			$(".js-banner-slider").each(function (index, element) {
				var $this = $(this);
	
				if ($this.find('.swiper-slide').length > 1) {
					$this.addClass('banner-slider--active');
					bannerSliderInstances[index] = new Swiper($this, {
						direction: 'horizontal',
						spaceBetween: 0,
						slidesPerView: 1,
						loop: true,
						speed: 1000,
						watchOverflow: true,
						navigation: {
							prevEl: $this.find(".js-banner-slider-btn-prev"),
							nextEl: $this.find(".js-banner-slider-btn-next")
						},
						pagination: {
							el: $this.find(".swiper-pagination")[0],
							type: 'bullets',
							clickable: true,
							hideOnClick: false
						}
					});
				}
			});
		}
	}
	
	bannerSliderInit();
});